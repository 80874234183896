import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Card, ProgressBar, Form, Spinner } from 'react-bootstrap';
import { getTestPayload, saveTest, submitTest, getAllTests } from './api'; // Assuming API calls are defined in api.js

const ExamTakingPage = ({ user }) => {
  const { testId } = useParams(); // Get testId from the URL
  const navigate = useNavigate(); // For navigation
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [notes, setNotes] = useState([]);
  const [likes, setLikes] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({}); // To store selected option indices
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [testSubmitted, setTestSubmitted] = useState(false); // Track if the test is submitted

  useEffect(() => {
    const fetchQuestions = async () => {
      if (user && user.email) {
        setLoading(true);
        try {
          const testPayload = await getTestPayload(user.email, testId);
          const allTests = await getAllTests(user.email);
          if (Array.isArray(testPayload)) {
            setQuestions(testPayload);
            setNotes(new Array(testPayload.length).fill(''));
            const initialSelectedOptions = {};
            testPayload.forEach((_, index) => {
              initialSelectedOptions[index] = []; // Initialize as an empty array for multiple selections
            });
            setSelectedOptions(initialSelectedOptions);
          } else {
            console.error('Invalid response: Expected an array but got', testPayload);
            setQuestions([]);
          }
          // Assuming correct answers are part of allTests response
          const correctTest = allTests.find(test => test.id === testId);
          setCorrectAnswers(correctTest ? correctTest.correctAnswers : {});
        } catch (error) {
          console.error('Error fetching questions:', error);
        } finally {
          setLoading(false);
        }
      } else {
        console.error('User is not logged in or email is missing');
      }
    };

    if (testId) {
      fetchQuestions();
    }
  }, [testId, user]);

  const handleSaveProgress = async () => {
    if (!user || !user.email) return;
    const questionData = {
      Index: currentQuestionIndex + 1,
      SelectedOptions: selectedOptions[currentQuestionIndex] // Save the selected options
    };
    try {
      const saveResponse = await saveTest(user.email, testId, questionData);
      if (!saveResponse || saveResponse.status !== 'success') {
        console.error('Failed to save test progress:', saveResponse);
      }
    } catch (error) {
      console.error('Error saving test progress:', error);
    }
  };

  const handleNextQuestion = async () => {
    setNextLoading(true);
    await handleSaveProgress(); // Save progress before moving to the next question
    setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % questions.length);
    setNextLoading(false);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => (prevIndex - 1 + questions.length) % questions.length);
  };

  const handleNotesChange = (e) => {
    const updatedNotes = [...notes];
    updatedNotes[currentQuestionIndex] = e.target.value;
    setNotes(updatedNotes);
  };

  const handleOptionSelect = (index) => {
    // Toggle selection for multiple choice
    setSelectedOptions((prev) => {
      const updatedSelections = [...(prev[currentQuestionIndex] || [])];
      if (updatedSelections.includes(index)) {
        updatedSelections.splice(updatedSelections.indexOf(index), 1); // Remove option if already selected
      } else {
        updatedSelections.push(index); // Add option if not selected
      }
      return { ...prev, [currentQuestionIndex]: updatedSelections };
    });
  };

  const handleLike = (questionIndex) => {
    setLikes((prev) => ({
      ...prev,
      [questionIndex]: { ...prev[questionIndex], liked: !prev[questionIndex]?.liked },
    }));
  };

  const handleDislike = (questionIndex) => {
    setLikes((prev) => ({
      ...prev,
      [questionIndex]: { ...prev[questionIndex], disliked: !prev[questionIndex]?.disliked },
    }));
  };

  const handleFavorite = (questionIndex) => {
    setLikes((prev) => ({
      ...prev,
      [questionIndex]: { ...prev[questionIndex], favorite: !prev[questionIndex]?.favorite },
    }));
  };

  const handleSubmitTest = async () => {
    setSubmitLoading(true);

    // Ensure the progress for the last question is saved before submitting
    if (currentQuestionIndex === questions.length - 1) {
      await handleSaveProgress(); // Save the last question's answer
    }

    try {
      const submitResponse = await submitTest(user.email, testId);

      if (submitResponse.status === 'In progress') {
        // Display the score and other info before navigating
        alert(`Test Completed! Your score is: ${submitResponse.Score}`);

        // Navigate to dashboard after showing the score
        //navigate('/dashboard');
      } else {
        console.error('Test submission failed:', submitResponse);
      }
    } catch (error) {
      console.error('Error submitting test:', error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const currentQuestion = questions[currentQuestionIndex];
  const isCorrect = selectedOptions[currentQuestionIndex] !== null &&
    Array.isArray(correctAnswers[currentQuestionIndex]) && // Ensure it's an array
    selectedOptions[currentQuestionIndex].every((optionIndex) => 
      correctAnswers[currentQuestionIndex].includes(optionIndex) // Check if all selected options are correct
    );

  if (loading) {
    return <p>Loading questions...</p>;
  }

  if (!Array.isArray(questions) || questions.length === 0) {
    return <p>No questions available.</p>;
  }

  return (
    <Container fluid>
      <Row>
        <Col md={8}>
          <Card className="mb-4">
            <Card.Body>
              <h5>Question {currentQuestion?.Index}</h5>
              <p>{currentQuestion?.Question}</p>
              {currentQuestion?.Options && (
                <Form>
                  {currentQuestion.Options.map((option, index) => (
                    <Form.Check
                      type="checkbox" // Change this to checkbox for multiple selections
                      label={option}
                      name={`question-${currentQuestion?.Index}`}
                      id={`option-${index}`}
                      key={index}
                      checked={selectedOptions[currentQuestionIndex]?.includes(index)}
                      onChange={() => handleOptionSelect(index)} // Handle multiple selections
                    />
                  ))}
                </Form>
              )}
              <div className="d-flex justify-content-between mt-4">
                <Button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
                  Previous
                </Button>
                <Button onClick={handleNextQuestion} disabled={currentQuestionIndex === questions.length - 1}>
                  {nextLoading ? <Spinner as="span" animation="border" size="sm" /> : 'Next'}
                </Button>
              </div>
              {currentQuestionIndex === questions.length - 1 && (
                <Button variant="success" className="mt-3" onClick={handleSubmitTest}>
                  {submitLoading ? <Spinner as="span" animation="border" size="sm" /> : 'Submit Test'}
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <div className="d-flex justify-content-around mb-3">
                <Button
                  variant={likes[currentQuestionIndex]?.liked ? 'success' : 'outline-success'}
                  onClick={() => handleLike(currentQuestionIndex)}
                >
                  👍
                </Button>
                <Button
                  variant={likes[currentQuestionIndex]?.disliked ? 'danger' : 'outline-danger'}
                  onClick={() => handleDislike(currentQuestionIndex)}
                >
                  👎
                </Button>
                <Button
                  variant={likes[currentQuestionIndex]?.favorite ? 'warning' : 'outline-warning'}
                  onClick={() => handleFavorite(currentQuestionIndex)}
                >
                  ⭐
                </Button>
              </div>

              <Form.Group>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={notes[currentQuestionIndex] || ''}
                  onChange={handleNotesChange}
                  placeholder="Write your notes here..."
                />
              </Form.Group>
              {testSubmitted && isCorrect !== undefined && (
                <div className={`mt-3 ${isCorrect ? 'text-success' : 'text-danger'}`}>
                  {isCorrect ? 'Correct' : 'Incorrect'}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <ProgressBar now={(currentQuestionIndex + 1) / questions.length * 100} />
          <div className="d-flex justify-content-center mt-2">
            {questions.map((q, index) => (
              <Button
                key={index}
                variant={index === currentQuestionIndex ? 'primary' : 'outline-primary'}
                onClick={() => setCurrentQuestionIndex(index)}
                className="mx-1"
              >
                {q.Index}
              </Button>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ExamTakingPage;
