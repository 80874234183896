import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card,ProgressBar, Form, Toast } from 'react-bootstrap';
import { fetchExamNames, fetchExamDetails, createTest, getAllTests, getTestPayload, deleteTest } from './api';
import { FaTrash } from 'react-icons/fa'; // Import trash icon
import { useNavigate } from 'react-router-dom';
import '../../assets/css/DashboardChatWindow.css';

const Dashboard = ({ user }) => {
    const [exams, setExams] = useState([]);
    const [selectedExam, setSelectedExam] = useState(null);
    const [testName, setTestName] = useState(''); // State for test name
    const [chatLog, setChatLog] = useState([]);
    const [sectionData, setSectionData] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});
    const [testList, setTestList] = useState([]);
    const [creatingNewTest, setCreatingNewTest] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allTests = await getAllTests(user.email);
                setTestList(allTests);
            } catch (error) {
                console.error('Error fetching test data:', error);
            }
        };

        fetchData();
    }, [user.email]);

    const handleCreateNewTest = async () => {
        try {
            const examNames = await fetchExamNames();
            setExams(examNames);
            setCreatingNewTest(true);
            setChatLog([
                { type: 'system', content: 'Please select an exam to create a new test.', style: { backgroundColor: '#e0e0e0' } },
                ...examNames.map(name => ({
                    type: 'system',
                    content: name,
                    style: { backgroundColor: '#f9f9f9' },
                    action: () => handleExamSelection(name),
                }))
            ]);
        } catch (error) {
            console.error('Error fetching exam names:', error);
        }
    };

    const handleExamSelection = async (examName) => {
        try {
            const examDetails = await fetchExamDetails(examName);
            setSelectedExam(examDetails);
            setSectionData(examDetails.Subjects || {});
            setChatLog([
                ...chatLog,
                { type: 'system', content: `You selected: ${examName}`, style: { backgroundColor: '#e0e0e0' } },
                { type: 'system', content: 'Please provide the details for the test.', style: { backgroundColor: '#e0e0e0' } }
            ]);
        } catch (error) {
            console.error('Error fetching exam details:', error);
        }
    };

    const handleTopicChange = (section, topic) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [section]: {
                ...(prevOptions[section] || { topics: {}, difficulty: {} }),
                topics: {
                    ...(prevOptions[section]?.topics || {}),
                    [topic]: !prevOptions[section]?.topics?.[topic],
                },
            },
        }));
    };

    const handleDifficultyChange = (section, difficulty) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [section]: {
                ...(prevOptions[section] || { topics: {}, difficulty: {} }),
                difficulty: {
                    ...(prevOptions[section]?.difficulty || {}),
                    [difficulty]: !prevOptions[section]?.difficulty?.[difficulty],
                },
            },
        }));
    };

    const handleQuestionNumberChange = (section, value) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [section]: {
                ...(prevOptions[section] || { topics: {}, difficulty: {}, questionCount: 0 }),
                questionCount: Math.min(value, sectionData[section]?.['Number Of Questions'] || 0),
            },
        }));
    };

    const handleSubmit = async () => {
        if (!testName.trim()) {
            setToastMessage('Test name is required');
            setShowToast(true);
            return;
        }
        const finalJson = {
            "TestName": testName,
            "Name": selectedExam?.Name || "",
            "Subjects": {}
        };

        Object.entries(selectedOptions).forEach(([section, { topics, questionCount, difficulty }]) => {
            finalJson.Subjects[section] = {
                "Topics": Object.keys(topics).filter(topic => topics[topic]),
                "Number Of Questions": questionCount || 0,
                "Difficulty Level": Object.keys(difficulty).filter(level => difficulty[level])
            };
        });

        setLoading(true);
        try {
            await createTest(user.email, finalJson);
            setToastMessage('Test created successfully');
            setShowToast(true);
            setTimeout(() => {
                navigate('/dashboard', { replace: true });
            }, 2000); // Delay navigation to allow toast to show
        } catch (error) {
            console.error('Error creating test:', error);
            setToastMessage('Error creating test. Please try again.');
            setShowToast(true);
        } finally {
            setLoading(false);
        }
    };

    const renderOptions = () => {
        return Object.entries(sectionData).map(([section, data]) => (
            <Card key={section} className="mb-4">
                <Card.Header as="h5">{section}</Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12} md={4}>
                            <strong>Topics:</strong>
                            {data.Topics.map(topic => (
                                <Form.Check
                                    key={topic}
                                    type="checkbox"
                                    id={`${section}-${topic}`}
                                    label={topic}
                                    onChange={() => handleTopicChange(section, topic)}
                                    checked={!!selectedOptions[section]?.topics?.[topic]}
                                    className="mb-2"
                                />
                            ))}
                        </Col>
                        <Col xs={12} md={4}>
                            <strong>Difficulty Level:</strong>
                            {['Easy', 'Medium', 'Hard'].map(difficulty => (
                                <Form.Check
                                    key={difficulty}
                                    type="checkbox"
                                    id={`${section}-${difficulty}`}
                                    label={difficulty}
                                    onChange={() => handleDifficultyChange(section, difficulty)}
                                    checked={!!selectedOptions[section]?.difficulty?.[difficulty]}
                                    className="mb-2"
                                />
                            ))}
                        </Col>
                        <Col xs={12} md={4}>
                            <strong>Number of Questions:</strong>
                            <Form.Control
                                type="number"
                                min="0"
                                max={data['Number Of Questions']}
                                value={selectedOptions[section]?.questionCount || data['Number Of Questions']}
                                onChange={(e) => handleQuestionNumberChange(section, e.target.value)}
                                className="mt-2"
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        ));
    };

    const renderChatInterface = () => {
        return (
            <div className="chat-container">
                <div className="chat-messages">
                    {chatLog.map((log, index) => (
                        <div key={index} className="chat-message" style={log.style || {}}>
                            <p>{log.content}</p>
                            {log.action && <Button variant="primary" onClick={log.action}>Select</Button>}
                        </div>
                    ))}

                    {selectedExam && (
                        <>
                            <div className="chat-message user-message">
                                <p>{selectedExam.Description}</p>
                            </div>
                            <Form.Group controlId="testName" className="mb-3">
    <Form.Label>Test Name</Form.Label>
    <Form.Control
        type="text"
        placeholder="Enter test name"
        value={testName}
        onChange={(e) => setTestName(e.target.value)}
        required
        isInvalid={testName === '' && showToast}  // Trigger invalid styling if test name is empty and a toast is shown
    />
    <Form.Control.Feedback type="invalid">
        Please enter a test name.
    </Form.Control.Feedback>
</Form.Group>

                            {renderOptions()}
                            <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                                {loading ? 'Creating...' : 'Create Test'}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        );
    };
    const handleDeleteTest = async (testId, emailId) => {
        setLoading(true);
        try {
            // Call deleteTest API
            //console.log(testId,emailId)
            const result = await deleteTest(emailId, testId);
    
            // Check if the result is a success
            if (result && result.message) {
                setToastMessage(result.message);  // Show the message from the server (e.g., "Test deleted successfully")
            } else {
                setToastMessage('Test deleted successfully');
            }
    
            // Update the state by removing the test from the list
            setTestList(prevTests => prevTests.filter(test => test.testId !== testId));
            setShowToast(true);
        } catch (error) {
            console.error('Error deleting test:', error);
            setToastMessage('Error deleting test. Please try again.');
            setShowToast(true);
        } finally {
            setLoading(false);
        }
    };
    
    
    const renderTestList = () => {
        if (!testList || testList.length === 0) {
            return (
                <>
                    <p>No tests available.</p>
                    <Button variant="primary" onClick={handleCreateNewTest}>Create New Test</Button>
                </>
            );
        }
    
        return (
            <>
                <h4 className="text-center mb-4">Your Tests</h4>
                <div className="test-tiles">
                {testList.map(test => (
    <Card key={test.TestId} className="mb-4 test-tile">
    <Button
        variant="danger"
        className="delete-icon"
        onClick={() => {
            
            handleDeleteTest(test.TestId, user.email);
        }}
        style={{ position: 'absolute', top: '10px', right: '10px', padding: '0.25rem 0.5rem' }}
    >
        <FaTrash />
    </Button>

        <Card.Body>
    <Card.Title></Card.Title>
    <Card.Text>
        <div><strong>Test Name:</strong> {test.TestName}</div>
        <div><strong>Created:</strong> {new Date(test.CreationTime).toLocaleString()}</div>
        <div><strong>Last Updated:</strong> {new Date(test.LastUpdateTime).toLocaleString()}</div>
        <div><strong>Status:</strong> {test.Status}</div>

        {test.Status === 'In progress' && (
            <>
                <div><strong>Progress:</strong> {test.CurrentQuestion} / {test.QuestionCount}</div>
                <ProgressBar 
                    now={(test.CurrentQuestion / test.QuestionCount) * 100} 
                    label={`${((test.CurrentQuestion / test.QuestionCount) * 100).toFixed(0)}%`} 
                    className="mt-2" 
                />
            </>
        )}

        {test.Status === 'Completed' && (
            <>
                <div><strong>Score:</strong> {test.Score}</div>
                <div><strong>Correct Answers:</strong> {test.CorrectCount}</div>
                <div><strong>Incorrect Answers:</strong> {test.IncorrectCount}</div>
                <div><strong>Unattempted Questions:</strong> {test.UnattemptedCount}</div>
            </>
        )}

        {/* Render In Progress and Not Started Test */}
        {test.Status !== 'Completed' && (
            <>
                <div><strong>Score:</strong> {test.Score}</div>
                {test.Status === 'In progress' && (
                    <ProgressBar 
                        now={parseInt(test.Score.split('/')[0])} 
                        max={parseInt(test.Score.split('/')[1])} 
                        className="mt-2" 
                    />
                )}
            </>
        )}
    </Card.Text>
    <Button
        variant={test.Status === 'In progress' ? 'success' : test.Status === 'Not started' ? 'primary' : 'secondary'}
        onClick={() => handleTestAction(test)}
    >
        {test.Status === 'In progress' ? 'Resume' : test.Status === 'Not started' ? 'Start' : 'Review'}
    </Button>
</Card.Body>



    </Card>
))}

                </div>
                <Button variant="primary" onClick={handleCreateNewTest}>Create New Test</Button>
            </>
        );
    };
    
    

    const handleTestAction = (test) => {
        //console.log('Navigating to test:', test.TestId, 'with email:', user.email);  // Debugging log
    
        if (test.Status === 'In progress') {
            navigate(`/exam/${test.TestId}`, { state: { email: user.email } });  // Passing email
        } else if (test.Status === 'Not started') {
            navigate(`/exam/${test.TestId}`, { state: { email: user.email } });  // Passing email
        } else if (test.Status === 'Completed') {
            navigate(`/exam/${test.TestId}`, { state: { email: user.email } });  // Passing email
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    {creatingNewTest ? renderChatInterface() : renderTestList()}
                </Col>
            </Row>
            <Toast 
                show={showToast} 
                onClose={() => setShowToast(false)} 
                bg={toastMessage.includes('success') ? 'success' : 'danger'} 
                delay={3000} 
                autohide
            >
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </Container>
    );
};

export default Dashboard;
